import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import ProTip from '../components/ProTip';
import Copyright from '../components/Copyright';
import Header from '../components/header';
import Content from '../components/Content';
import PageIntro from '../components/pageIntro';
import Card from '@material-ui/core/Card';
import List from '@material-ui/core/List';
import ListItem, { ListItemProps } from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Chip from '@material-ui/core/Chip';
import PageTitle from '../components/pageTitle';
import Avatar from '@material-ui/core/Avatar';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PhoneEnabledIcon from '@material-ui/icons/PhoneEnabled';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';




const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    cardShadow: {
      boxShadow: '5px 5px 20px #e0e0e0',
  }
  }),
);

export default function About() {
  const classes = useStyles();

  return (
    <Grid Container direction="column">
      <Grid item>
        <Header title="Info"/>
      </Grid>
      <Grid item>
      <Container maxWidth="sm">
      <Box my={4}>
      <PageTitle/>
        <PageIntro content="Vous pouvez me contacter directement par email ou par téléphone à l'aide des informations ci-dessous" />
        <Card className={classes.cardShadow}>
        <div className={classes.root}>
      <List component="nav" aria-label="main mailbox folders">
      <a style={{textDecoration:"none", color:"black"}} href='mailto:thomas.bompaire@gmail.com'>
        <ListItem button>
        <ListItemIcon>
            <MailOutlineIcon />
          </ListItemIcon>
          <ListItemText primary="thomas.bompaire@gmail.com" />
        </ListItem>
        </a>
        <Divider />
        <a style={{textDecoration:"none", color:"black"}} href='tel:+33614780058'>
        <ListItem button>
        <ListItemIcon>
            <PhoneEnabledIcon />
          </ListItemIcon>
          <ListItemText primary="06 14 78 00 58" />
        </ListItem>
        </a>
        <Divider />
        <a style={{textDecoration:"none", color:"black"}} href='https://www.southside-interactive.com/wp-content/uploads/2021/04/Thomas-Bompaire-CV-2021.pdf' download="CV.pdf">
        <ListItem button>
        <ListItemIcon>
            <DescriptionOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="CV 2021" />
        </ListItem>
        </a>
      </List>
    </div>
        </Card>
      </Box>
      </Container>
      </Grid>
    </Grid>
  );
}
