import {
 AppBar,
 makeStyles,
 Toolbar,
 Typography,
 Button,
 IconButton,
} from "@material-ui/core";
import React from "react";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Chip from '@material-ui/core/Chip';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'gatsby-theme-material-ui';

const useStyles = makeStyles(() => ({
 root: {
  flexGrow: 1,
 },
 title: {
  flexGrow: 1,
  textAlign: "center",
  fontWeight: "700",
  fontSize: "1.5em",
 },
 appbarShadow: {
  boxShadow: "2px 2px 5px #e0e0e0",
 },
}));

const Header = (props) => {
 const { title } = props;
 const classes = useStyles();
 return (
  <AppBar
   className={classes.appbarShadow}
   position="fixed"
   color="white"
   style={{ borderBottom: "1px solid #dddddd" }}
  >
   <Container maxWidth="md">
    <Toolbar variant="dense">
     <IconButton component={Link} to="/"
      edge="start"
      className={classes.menuButton}
      color="inherit"
      aria-label="menu"
     >
      <ArrowBackIcon />
     </IconButton>
     <Typography variant="h1" className={classes.title}>{title}</Typography>
     <Chip
        icon={<InfoIcon />}
        label="Info"
        href="/info"
        component="a"
        clickable
      />
    </Toolbar>
   </Container>
  </AppBar>
 );
};

export default Header;
